import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import trackVisitor from "./utilities/tracker";

import GameResult from "./js/GameResult";
const momenttz = require("moment-timezone");
function App() {
  useEffect(() => {
    trackVisitor();
  }, []);
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display

  const location = useLocation();
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isAbout = location.pathname.includes("/about-us");
  const isPrivacy = location.pathname.includes("/privacy-policy");

  const todayDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "WAIT",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);
  // pagescroll
  function pageScroll() {
    const tableSection = document.getElementById("monthTable");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <div className="">
      <div className="col-md-12 menu text-center">
        <div className="row">
          <div className="col-md-1 col-sm-12 nav-link">
            <a class="text-white" href="/">
              HOME{" "}
            </a>
          </div>
          <div className="col-md-3 col-sm-12 nav-link">
            <a class="text-white" href="#">
              SATTA KING 786
            </a>
          </div>
          <div className="col-md-3 col-sm-12 nav-link">
            <a class="text-white" onClick={pageScroll}>
              SATTA CHART
            </a>
          </div>
          <div className="col-md-3 col-sm-12 nav-link">
            <a class="text-white" href="#">
              SATTA MATKA
            </a>
          </div>
          <div className="col-md-2 col-sm-12 nav-link">
            <a class="text-white" href="http://satta-leak.co/">
              SATTA LEAK
            </a>
          </div>
        </div>
        <div className="text-center highlight p-1 king">
          <h1 className="fw-bold">SATTA KING</h1>
        </div>
      </div>

      <div className="text-center mt-4">
        <div className="text-center p-1  fw-bold text-white themecolor">
          <p> SATTA KING BEST SITE SATTA RESULT</p>
          <h2>WWW.SATTA-NO.COM</h2>
        </div>

        <div className="text-center backcolor">
          <p className="text-danger fw-bold fs-3 mb-0">{todayDate}</p>
          <p className="text-dark fw-bold fs-5 mb-0">
            Satta King Live Result Today
          </p>
          {datagame?.map((todayData, index) => (
            <div key={index} className="game">
              <h3 className="fw-bold mb-0 fs-2 livegame">
                {todayData?.gameName}
              </h3>
              <h5 className=" blinking-text fw-bold mb-0 fs-2">
                {todayData?.result || ""}
              </h5>
            </div>
          ))}
        </div>
        <GameResult dayGameData={data} />
      </div>
    </div>
  );
}

export default App;
